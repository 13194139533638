.Tabs {
  display: flex;
  flex-direction: column;
  max-height: 100vh;
}

.TabsNavigation {
  list-style-type: none;
  display: flex;
  gap: 8px;
}

.TabsNavigationItem {
  cursor: pointer;
  padding: 8px 16px 5px;
  background-color: #3b3733;
  border-radius: 4px 4px 0px 0px;
}

.TabsNavigationItem--Active {
  background-color: #5a5149;
}

.TabsContent {
  border: solid 3px #5a5149;
  padding: 8px 10px;
  border-radius: 0px 0px 4px 4px;
  height: 400px;
  overflow-y: auto;
}

.TabsContentTitle {
  background-color: #5a5149;
  padding: 4px 9px;
  text-align: center;
  margin-bottom: 8px;
}

.TabsContent li {
  margin-bottom: 8px;
}
