.SimulationTime {
  position: fixed;
  font-size: 1.3em;

  left: 50%;
  transform: translate(-50%, 0px);
  color: #fff;
  display: flex;
  align-items: flex-start;
}

.SimulationTimeDetails {
  background-color: #3b3733;
  padding: 8px 10px;
  border-radius: 0px 0px 10px 10px;
  box-shadow: 0px 0px 7px 1px rgb(0 0 0 / 40%);
  text-align: center;
}

.SimulationTimeButton {
  border-radius: 0px 0px 4px 4px;
}
