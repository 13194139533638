.Dialog {
  position: fixed;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}

.DialogHeader {
  display: flex;
  gap: 8px;
  align-items: center;
}

.DialogContent {
  font-size: 1.1em;
}

.DialogContainer {
  margin: 50px auto 0px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: #fff;
  width: 100%;
  font-size: 1.3em;
  background-color: #222;
  border: solid 3px #5a5149;
  padding: 8px;
  border-radius: 2px;
  position: relative;
  box-shadow: 0px 2px 10px 1px rgb(0, 0, 0);
}

.DialogContainerClose {
  position: absolute;
  font-size: 2em;
  top: 3px;
  right: 10px;
  cursor: pointer;
}

.DialogHeaderTitle {
  font-size: 2em;
}

.DialogHeaderSubtitle {
  font-size: 1.6em;
}
