* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-size: 10px;
  font-family: monospace;
  background-color: #3b3733;
}

* {
  scrollbar-width: auto;
  scrollbar-color: #5a5149 #3b3733;
}

*::-webkit-scrollbar {
  width: 16px;
}

*::-webkit-scrollbar-track {
  background-color: #3b3733;
}

*::-webkit-scrollbar-thumb {
  background-color: #5a5149;
  border-radius: 10px;
  border: 3px solid #3b3733;
}

a {
  color: #90ff36;
}

p {
  margin-bottom: 1em;
}

li {
  list-style-position: inside;
}

input {
  background-color: #5a5149;
  border: #000;
  border-radius: 4px;
  padding: 8px;
  display: block;
  width: 100%;
  margin-top: 4px;
  color: #fff;
}

button {
  background-color: #c56000;
  border: none;
  padding: 6px;
  color: #fff;
  width: 100%;
  cursor: pointer;
  border-radius: 4px;
  text-transform: uppercase;
  box-shadow: 0 1px 1px 2px rgb(0 0 0 / 50%);
  transition: box-shadow 0.2s ease;
}

button:active {
  box-shadow: none;
}

button:disabled {
  cursor: none;
  background-color: #606060;
  color: #818181;
}
