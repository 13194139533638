.SimulationMap {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  z-index: -100;
  overflow: scroll;
}

.SimulationMapCanvas {
  margin: auto;
}
